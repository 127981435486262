import React from "react";
import "../Css/Award.css";
import { Image } from "antd";
import demo from "../img/aboutUspng.jpg";
import Footer from "../Components/Footer";
import award1 from "../img/Certificates/1.jpg";
import award2 from "../img/Certificates/2.jpg";
import award3 from "../img/Certificates/3.jpeg";
import award4 from "../img/Certificates/4.jpeg";
import award5 from "../img/Certificates/5.jpeg";
import award6 from "../img/Certificates/6.jpeg";
import award7 from "../img/Certificates/7.jpeg";
import award8 from "../img/Certificates/8.jpeg";
import award9 from "../img/Certificates/9.jpeg";
import award10 from "../img/Certificates/10.jpeg";
import award11 from "../img/Certificates/11.jpeg";
import award12 from "../img/Certificates/12.jpeg";
import award13 from "../img/Certificates/13.jpeg";
import award14 from "../img/Certificates/14.jpeg";
import award15 from "../img/Certificates/15.jpeg";
import PopUp from "./PopUp";
const Award = () => {
    return (
        <>
            {/* =========== Section 1 ========== */}
            <div className="award-Section1">
                <div className="awardBanner">
                    <div className="container">
                        <div className="row paddingSection">
                            <div className="col-md-6">
                                <div className="award_BannerDesc">
                                    <h1>Awards</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="award-section-2">
                <div className="container">
                    <div className="award-section-2-main-con">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award1} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award2} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award3} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award4} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award5} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award6} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award7} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award8} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award9} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award10} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award11} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award12} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award13} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award14} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="award-section-image-main-con">
                                    <Image src={award15} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <PopUp/> */}
            <Footer />
        </>
    )
};

export default Award;