import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LuParkingCircle } from "react-icons/lu";
import { BiBed } from "react-icons/bi";
import Footer from "./Footer";
import CommercialData from "./CommercialData";
import "../Css/FilterProperty.css";
import { Button, Form, Input, Pagination, Select } from "antd";
import { IoSearch } from "react-icons/io5";
import { get } from "../services/ApiRouting";
import proprtydemo from "../img/demo image.avif";
import PopUp from "../Pages/PopUp";
const { Option } = Select;

// utils/priceFormatter.js

export const formatPrice = (price) => {
    if (price >= 10000000) {
        return (price / 10000000).toFixed(2) + ' Cr';
    } else if (price >= 100000) {
        return (price / 100000).toFixed(2) + ' Lacs';
    } else if (price >= 1000) {
        return (price / 1000).toFixed(2) + ' K';
    } else {
        return price.toString();
    }
};


const CommercialProperty = (() => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [residental, setResidental] = useState(null)

    // Search form 
    const [form] = Form.useForm();
    const [propertyType, setPropertyType] = useState(null)
    const [locality, setLocality] = useState(null)
    const [propertySpace, setPropertySpace] = useState(null)
    const [usableCarpet, setUsableCarpet] = useState(null)
    const [reraCarpet, setReraCarpet] = useState(null)
    const [maxPrice, setMaxPrice] = useState(null)
    const [minPrice, setMinPrice] = useState(null)

    const onFinish = ((values) => {
        console.log("Search Value", values)
        setPropertyType(values.property_type);
        setLocality(values.locality)
        setPropertySpace(values.property_space);
        setUsableCarpet(values.usable_carpet);
        setReraCarpet(values.rera_carpet);
        setMaxPrice(values.max_price);
        setMinPrice(values.min_price);
    })

    const onClear = () => {
        form.resetFields();
        setPropertyType(null);
        setLocality(null);
        setPropertySpace(null);
        setUsableCarpet(null);
        setReraCarpet(null);
        setMaxPrice(null);
        setMinPrice(null);
    };

    // fetch api 

    const fetchData = async (page, limit, propertyType, locality, propertySpace, usableCarpet, reraCarpet, maxPrice, minPrice) => {
        try {
            const queryParams = [];

            if (propertyType) queryParams.push(`property_type=${propertyType}`);
            if (locality) queryParams.push(`locality=${locality}`);
            if (propertySpace) queryParams.push(`property_space=${propertySpace}`);
            if (usableCarpet) queryParams.push(`usable_carpet=${usableCarpet}`);
            if (reraCarpet) queryParams.push(`rera_carpet=${reraCarpet}`);
            if (maxPrice) queryParams.push(`max_price=${maxPrice}`);
            if (minPrice) queryParams.push(`min_price=${minPrice}`);

            const queryString = queryParams.length ? `&${queryParams.join('&')}` : '';

            const response = await get(`/property.php?action=fetch&property_categ=commercial&limit=${limit}&page=${page}${queryString}`);

            console.log("residentalnew", response.list);
            setResidental(response.list);
            setTotal(response.total);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const [localityData, setLocalityData] = useState()

    const fetchLocalityData = async () => {
        try {
            const response = await get(`/property.php?action=fetchLocation`);
            // console.log("Locality Fetch", response.list)
            setLocalityData(response.list)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    useEffect(() => {
        // fetchData();
        fetchLocalityData()
    }, []);

    // Pagination 

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(9);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        const dbounceData = setTimeout(() => {
            fetchData(page, limit, propertyType, locality, propertySpace, usableCarpet, reraCarpet, maxPrice, minPrice);
        }, 300);
        return () => clearTimeout(dbounceData);
    }, [page, limit, propertyType, locality, propertySpace, usableCarpet, reraCarpet, maxPrice, minPrice]);


    return (
        <>
            {/* =========== Property Section 1 ========== */}
            <div className="property-Section1">
                <div className="propertyBanner">
                    <div className="container">
                        <div className="row paddingSection">
                            <div className="col-md-12">
                                <div className="property_BannerDesc">
                                    <h1>Commercial Property</h1>
                                    {/* <p>
                                        The apartments will have large windows and balconies to
                                        allow for natural light and oxygenated air.
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="property-filter-main-con">
                <div className="container">
                    <div className="property-filter-data-con-main">
                        <div className="property-filter-data-con">
                            <Form layout="vertical" onFinish={onFinish} form={form}>
                                <div className="property-filter-inputs-main-con">
                                    <div className="first-filter-con-main">
                                        <div className="row">
                                            <div className="col-md-2 filter-border-con">
                                                <div className="property-filter-inputs">
                                                    <Form.Item name="property_type">
                                                        <Select placeholder="Select">
                                                            <Option value="Outright">Outright</Option>
                                                            <Option value="Lease">Lease</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-md-3 filter-border-con">
                                                <div className="property-filter-inputs">
                                                    <Form.Item name="locality">
                                                        <Select placeholder="Locality">
                                                            {
                                                                localityData && localityData.map((elem) => {
                                                                    const { locality } = elem
                                                                    return (
                                                                        <>
                                                                            <Option value={locality}>{locality}</Option>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-md-3 filter-border-con">
                                                <div className="property-filter-inputs">
                                                    <Form.Item name="property_space">
                                                        <Select placeholder="Select Configuration">
                                                            <Option value="Any">Any</Option>
                                                            <Option value="1 BHK">1 BHK</Option>
                                                            <Option value="2 BHK">2 BHK</Option>
                                                            <Option value="3 BHK">3 BHK</Option>
                                                            <Option value="4 BHK">4 BHK</Option>
                                                            <Option value="5 BHK">5 BHK</Option>
                                                            <Option value="6 BHK">6 BHK</Option>
                                                            <Option value="7 BHK">7 BHK</Option>
                                                            <Option value="8 BHK">8 BHK</Option>
                                                            <Option value="9 BHK">9 BHK</Option>
                                                            <Option value="10 BHK">10 BHK</Option>
                                                            <Option value="Piots">Plots</Option>
                                                            <Option value="Bunglows">Bunglows</Option>
                                                            <Option value="Preleased">Preleased</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-md-2 filter-border-con">
                                                <div className="property-filter-inputs">
                                                    <Form.Item name="usable_carpet">
                                                        <Input placeholder="Max Usable Carpet Sq.ft" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="property-filter-inputs">
                                                    <Form.Item name="rera_carpet">
                                                        <Input placeholder="Max Carpet Area Sq.ft." />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="second-filter-con-main">
                                        <div className="second-filter-under-con">
                                            <div className="row">
                                                <div className="col-md-3 filter-border-con">
                                                    <div className="property-filter-inputs">
                                                        <Form.Item name="min_price">
                                                            <Select placeholder="Min Price">
                                                                <Option value="50 Lack">50 Lack</Option>
                                                                <Option value="80 Lack">80 Lack</Option>
                                                                <Option value="1 Cr">1 Cr</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 filter-border-con">
                                                    <div className="property-filter-inputs">
                                                        <Form.Item name="max_price">
                                                            <Select placeholder="Max Price">
                                                                <Option value="80 Lack">80 Lack</Option>
                                                                <Option value="1 Cr">1 Cr</Option>
                                                                <Option value="50 Cr">50 Cr</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="property-filter-inputs property-filter-inputs-search">
                                                        <Form.Item>
                                                            <Button htmlType="submit">
                                                                <IoSearch /> Search
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="property-filter-inputs property-filter-inputs-clear">
                                                        <Form.Item>
                                                            <Button onClick={onClear}>
                                                                Clear
                                                            </Button>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>


            {/* =========== Property Section 2 ========== */}

            <section className="property-Section2">
                <div className="container">
                    <div className="headTitle">
                        <h2>PROPERTY LIST</h2>
                    </div>
                    <div className="row">
                        {
                            residental ? (
                                <>
                                    {residental && residental.map((ele) => {
                                        const { images, property_type, property_name, property_address, bathroom, parking, price, property_categ, usable_carpet, property_slug, locality } = ele;
                                        const imageUrl = images.length > 0 ? images[0].image_link : proprtydemo;
                                        return (
                                            <div className="col-lg-4 col-md-6" key={property_slug}>
                                                <div className="package-item">
                                                    <div className="home_proImg">
                                                        <img
                                                            className="img-fluid"
                                                            src={imageUrl}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = proprtydemo; }}
                                                        />
                                                        <div className="home_proTxt1">
                                                            <a className="home_probtn1" href="#">
                                                                New Listing
                                                            </a>
                                                            <a className={`home_probtn2 ${property_type === 'outright' ? 'outright' : property_type === 'lease' ? 'lease' : ''}`} href="#">
                                                                {property_type}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="home_prodesc">
                                                        <h2>{property_name}</h2>
                                                        <p>{property_address}</p>
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="home_prolist">
                                                                    <h6>BedRooms</h6>
                                                                    <i>
                                                                        <BiBed />
                                                                    </i>
                                                                    <span> {bathroom}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="home_prolist">
                                                                    <h6>Parking</h6>
                                                                    <i>
                                                                        <LuParkingCircle />
                                                                    </i>
                                                                    <span> {parking}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="home_prolist">
                                                                    <h6>Area</h6>
                                                                    <span>{usable_carpet}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="home_proprice">
                                                                    <h5>
                                                                        For Sale <span> {formatPrice(price)}</span>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="pro_btn">
                                                                    <Link to={`/${property_slug}/${locality}/${property_categ}`}>Details</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <div className="property-section-2-not-found-image-con">
                                    <div className="property-section-2-not-found-image">
                                        <img src={proprtydemo} alt="" />
                                        <h3>Property Not Found</h3>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div className="pagination-main-con">
                        <Pagination
                            hideOnSinglePage
                            current={page}
                            total={total}
                            pageSize={limit}
                            onChange={(page) => setPage(page)} />
                    </div>
                </div>
            </section>
            {/* <PopUp/> */}
            <Footer />
        </>
    )
});

export default CommercialProperty;