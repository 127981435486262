import React, { useEffect } from 'react';
import "../../Components/NavBar/Header.css"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// Logo
import Logo from "../../img/Header Logo.png";
import { Link } from 'react-router-dom';
// social icons 
import { AiFillInstagram } from "react-icons/ai";
import { TiSocialFacebook } from "react-icons/ti";
import { IoIosMail } from "react-icons/io";
import { IoIosCall } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
const Header = () => {

    return (
        <>
            <Navbar collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <Link to="/">
                            <div className='Logo-Con'>
                                <img src={Logo} alt="" />
                            </div>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav
                            className="my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="/"><Link to="/">Home</Link></Nav.Link>
                            <Nav.Link href="/about"><Link to="/about-us">About Us</Link></Nav.Link>
                            <Nav.Link href="/services"><Link to="/services">Services</Link></Nav.Link>
                            <Nav.Link href="/award"><Link to="/award ">Awards</Link></Nav.Link>
                            <Nav.Link href="/residential-property"><Link to="/residential-property">residential property</Link></Nav.Link>
                            <Nav.Link href="/commercial-property"><Link to="/commercial-property">commercial property</Link></Nav.Link>
                            <Nav.Link href="/contact-us"><Link to="/contact-us">Contact us</Link></Nav.Link>
                        </Nav>
                        <Nav>
                            {/* <div className='social-media-links-con'>
                                <div className='social-media-links'>
                                    <a href='' target='blank'>
                                        <AiFillInstagram />
                                    </a>
                                    <a href='' target='blank'>
                                        <TiSocialFacebook />
                                    </a>
                                    <a href="" target='blank'>
                                        <IoIosMail />
                                    </a>
                                    <a href="">
                                        <IoIosCall />
                                    </a>
                                </div>
                            </div> */}
                            {/* <div className='header-login-register-con'>
                                <div className='header-login-btn'>
                                    <Link to="/account/sign-in">
                                        <button>
                                            Login
                                        </button>
                                    </Link>
                                </div>
                                <div className='header-register-btn'>
                                    <Link to="/account/sign-up">
                                        <button>
                                            Register
                                        </button>
                                    </Link>
                                </div>
                            </div> */}
                            {/* {
                                token ? (
                                    <>
                                        <div className='header-profile-main-con'>
                                            <Link to="/profiles">
                                                <div className='header-profile-con'>
                                                    <FaUser />
                                                    <h3>Siddhesh</h3>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                    <div className='header-navbar-section-login-register-main-con'>
                                        <div className='header-profile-main-con'>
                                            <Link to="/account/sign-in">
                                                <div className='header-profile-con'>
                                                    <FaUser />
                                                    <h3>Login</h3>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className='header-profile-main-con'>
                                            <Link to="/account/sign-up">
                                                <div className='header-profile-con'>
                                                    <FaUser />
                                                    <h3>Register</h3>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    </>
                                )
                            } */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header